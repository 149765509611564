.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  &.padding-lg-h {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  &.with-maxwidth {
    max-width: $container_fluid_maxwidth;
  }
  // &.with-maxwidth {
  //   max-width: none;
  // }
  &.with-maxwidth-lg {
    max-width: map-get($container-max-widths, lg);
  }
  &.with-maxwidth-md {
    max-width: map-get($container-max-widths, lg);
  }
  &.with-maxwidth-sm {
    max-width: map-get($container-max-widths, md);
  }
  &.no-breadcrumbs {
    padding-top: 1.5em;
    padding-bottom: 1em;
    padding-left: 15px;
    padding-right: 15px;
  }
}