.page-maintenance {
  .top-header {
    padding: 1em 0;
    border-bottom: 1px solid $state-default-border;
    font-size: 32px;
    line-height: 1;

    a.logo {
      text-decoration: none;
      color: $body-color;
    }
  }

  .content {
    max-width: map-get($container-max-widths, xl);
    margin: 50px auto 0;
  }

  .main-content {
    margin-bottom: 80px;
    h1 {
      text-transform: uppercase;
      font-size: 32px;
      margin-bottom: 15px;
    }

    p {
      font-size: 22px;

    }
  }
}

.theme-dark,
.theme-gray {
  .page-maintenance {
    .top-header {
      a.logo {
        color: #fff;
      }
    }
  }
}

