
@mixin bg-style ($background, $color) {
  background-color: $background;
  color: $color;
  &:hover {
    background-color: $background;
    color: $color;
  }
  a {
    color: $color;
    &:hover {
      color: $color;
    }
  }
}

// dirty fix
.bg-color-dark .bg-color-light a {
  color: $body-color;
}

.bg-color-body { @include bg-style($body-bg, $body-color); }
.bg-color-page { @include bg-style($page-bg, $body-color); }

.bg-color-light { @include bg-style($white, $body-color); }
.bg-color-white { @include bg-style(#fff, $body-color); }

.bg-color-gray { @include bg-style($gray-light, #fff); }
.bg-color-dark { @include bg-style($dark, #fff); }
.bg-color-primary { @include bg-style(theme-color("primary"), #fff); }
.bg-color-success { @include bg-style(theme-color("success"), #fff); }
.bg-color-info { @include bg-style(theme-color("info"), #fff); }
.bg-color-warning { @include bg-style(theme-color("warning"), $body-color); }
.bg-color-danger { @include bg-style(theme-color("danger"), #fff); }


// for mdl-data-table
.mdl-data-table tbody {
  .bg-color-dark { @include bg-style($dark, #fff); }
  .bg-color-primary { @include bg-style(theme-color("primary"), #fff); }
  .bg-color-success { @include bg-style(theme-color("success"), #fff); }
  .bg-color-info { @include bg-style(theme-color("info"), #fff); }
  .bg-color-danger { @include bg-style(theme-color("danger"), #fff); }
}

