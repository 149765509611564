

// 
@mixin divider() {
  display: block;
  border: 0;
  border-top: 1px solid transparent;
  &.divider-solid {
    border-style: solid;
    border-color: $state-default-border;
  }
  &.divider-dashed {
    border-style: dashed;
    border-color: $state-default-border;
  }
  &.divider-dotted {
    border-style: dotted;
    border-color: $state-default-border;
  }
}
.divider {
  @include divider();
  margin-top: 10px;
  margin-bottom: 10px;
}
.divider-xs {
  margin-top: 3px; 
  margin-bottom: 3px; 
}
.divider-sm { 
  margin-top: 5px; 
  margin-bottom: 5px; 
}
.divider-md { 
  margin-top: 15px; 
  margin-bottom: 15px; 
}
.divider-lg { 
  margin-top: 20px;
  margin-bottom: 20px;
}
.divider-xl { 
  margin-top: 30px;
  margin-bottom: 30px;
}
.divider-xxl { 
  margin-top: 50px;
  margin-bottom: 50px;
}

// 
.space {
  display: inline;
  padding: 6px;
}
.space-md { padding: 15px; }
.space-lg { padding: 25px; }

// 
span.block {
  display: block;
}
.no-margin { margin: 0 !important; }
.no-margin-h {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-margin-top { margin-top: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.no-margin-right { margin-right: 0 !important; }

.no-border {
  border: 0 !important;
}
.no-border-radius {
  border-radius: 0;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-shadow {
  box-shadow: 0;
}
.full-width {
  width: 100%;
}
.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

// 
.margin-b-lg {
  margin-bottom: 30px;
}
.nav-mat-tab-link{
  height: 60px;
  // padding: 24px 25px 35px;
      cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 160px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  vertical-align: top;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.nav-btn .mat-button{
  height:60px !important}
  
  .nav-btn .mat-button::ng-deep .mat-button-focus-overlay {
    background: none !important;
  }
  .nav-btn .mat-button:hover{
    background: none !important;
  }
  .nav-btn .mat-button .mat-button-focus-overlay {
    background-color: transparent!important;
}
  a.h5.text-primary.px-1{
    font-size: 14px !important;
    // text-transform: capitalize;
    font-weight: 500 !important;
  }
  .circle {
    position: relative;
    display: block;
    margin: 3rem auto;
    height: 220px;
    width: 220px;
    background-color: transparent;
    color: #222;
    text-align: center;
  }
  
  .circle:after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #ff7c7c;
    content: "";
  }
  
  .circle__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .circle__wrapper {
    display: table;
    width: 100%;
    height: 100%;
  }
  
  .circle__content {
    display: table-cell;
    font-size: 20px !important;
    line-height: 1.2;
    font-weight: 500;
    vertical-align: middle;
  }
  
  @media (min-width: 480px) {
    .circle__content {
      font-size: 20px !important;
      line-height: 1.2;
      font-weight: 500;
    }
  }
  
  @media (min-width: 768px) {
    .circle__content {
      font-size: 20px !important;
      line-height: 1.2;
      font-weight: 500;
    }
  }