// overrides mdl
address {
  line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $headings-line-height;
}

.flags-new {
  width: 28px;
  height: 30px;
}

.flags {
  width: 14px;
  height: 15px;
}

//mobile compatibility for dialog boxes title and close icon
@media screen and (max-width: 425px) {
  .IssueTrackerTitle {
    max-width: 87%;
  }

  .closebtn {
    min-width: 13%;
    margin-top: 20px;
  }

  .closebtng {
    min-width: 13%;
    margin-top: 6px;
  }

  .coverageTitle {
    max-width: 60%;
  }

  .closebtncoverage {
    max-width: 7%;
    margin-right: -68px;
    margin-top: -45px;
  }

  .closebtnrbac {
    margin-left: 210px;
    bottom: 108px;
  }

  section.container-fluid.with-maxwidth.no-breadcrumbs.chapter {
    font-size: 12px;
  }
}

// Responsive toggle buttons
mat-button-toggle-group {
  flex-wrap: wrap;
}

input::file-selector-button {
  cursor: pointer;
  border: 2px solid #dce4ec;
  border-radius: 5px;
  height: 35px;

}


.borderBottom {
  border-bottom: 2px solid #2e95e9 !important;
  border-radius: 0 !important;
    // opacity: 1 !important;
    // color: #000;

}

button#button1-button {
  min-width: 36px;
  height: 34px;
  width: 38px;
  right: 4px;
  bottom: 3px;
}

.nav button {
  opacity: 0.6;
  font-size: 14px;
  height: 48px;
  min-width: 160px;
}

.bg-primary {
  border-radius: 3px;
  padding: 3px;
}

@media (max-width: 812px) {
  .responsive-table {
    overflow-x: auto !important;
  }

  .mat-table {
    min-width: 800px;
  }

  //application title in mobile view
  .top-nav-left.d-none.d-lg-inline-block.d-xl-inline-block {
    display: block !important;
    float: left;
  }

  .container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  h5.text-primary.mt-5 {
    text-align: center;
    margin-left: 40px;
  }

  span.badge.badge-warning.d-inline {
    display: inline-block !important;
  }

  nav.nav {
    width: 320px;
  }

  .totalscans {
    padding-top: 20px;
  }

  .mat-dialog-container {
    height: 75vh !important;
  }
}

.rowHover:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.nav-btn .mat-button::ng-deep .mat-button-focus-overlay {
  background: none !important;
}

.pointer {
  cursor: pointer;
}

.joyride-step__container {
  border-radius: 5px !important;
}

.joyride-button {
  border-radius: 5px !important;
}

.profileWidth {
  width: 200px;
}

.profileWidth1 {
  width: 250px;

  .mat-menu-content:not(:empty) {
    padding: 0px !important;
  }

  .list-scroll {
    height: 250px !important;
    max-height: 400px !important;
    overflow-y: auto !important;

    .mat-menu-item {
      line-height: 18px
    }

    margin-top: 15px;
  }
}

.custom-tabs {
  position: sticky;
  top: 60px;
  z-index: 100;
  background: white;
  opacity: 1;
}

.projectNameClass {
  opacity: 1 !important;
  color: black !important;
}

.textColor .ace-github {

  .ace_string,
  .ace_constant.ace_numeric {
    color: black !important;
  }
}

.textColor {

  .ace_string,
  .ace_constant.ace_numeric {
    color: black !important;
  }
}


#swagger-editor {
  font-size: 1.3em;

  margin-top: 5px;
}

.menu-width {
  max-width: 335px !important;
}

.container {
  height: 700px;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

#editor-wrapper {
  height: 100%;
  border: 1em solid #000;
  border: none;
}

.Pane2 {
  overflow-y: scroll;
}

.menu-pagination {
  display: contents;
  border: none;
  background: white;

  .mat-paginator-range-label {
    cursor: not-allowed;
    pointer-events: none;

    /*Button disabled - CSS color class*/
    color: #c0c0c0;
    background-color: #ffffff;
  }

  .mat-paginator-page-size {
    display: none;
  }

}

//Override default font style
.mat-table,
.mat-form-field,
.mat-menu-content,
.mat-menu-panel,
.mat-button-wrapper,
.mat-menu-item,
.mat-slide-toggle-content,
.mat-button-toggle-button,
.mat-button-toggle-label-content,
.mat-select,
.mat-list-base,
.mat-nav-list .mat-list-item-content,
.mat-select-value,
.mat-select-trigger,
.mat-form-field-wrapper,
.mat-option,
// .mat-focus-indicator,
.mat-selected,
.mat-active .mat-checkbox,
.mat-checkbox-label,
.mat-dialog-title,
.mat-dialog-container,
.mat-radio-button,
.mat-radio-label,
.mat-radio-container,
.callout {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.mat-dialog-title,
.mat-dialog-container,
.mat-radio-button,
.mat-radio-label,
.mat-radio-container,
.callout,
.mat-select-value,
.mat-form-field,
.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description,
.mat-button-toggle-group,
.mat-menu-content,
.mat-menu-item {
  color: #6b778c !important;
}

.text-dark {
  color: #6b778c !important;
}

// mat-row,
// mat-footer-row {
//   // min-height: 75px;
//   column-gap: 0;
//   grid-template-columns: 100px 150px 150px 150px 1fr;
//   border-radius: 10px;
//   overflow: hidden;
//   // background-color: rgb(248, 248, 248);
//   background-color: white;
//   margin: 10px;
//   border-bottom-width: 0;
// }

// mat-header-row {
//   column-gap: 0;
//   grid-template-columns: 100px 150px 150px 150px 1fr;
//   overflow: hidden;
//   cursor: pointer;
//   margin: 6px;
//   border-bottom-width: 0;
//   text-transform: uppercase;
// }

// .mat-table {
//   background-color: #f8f8f8 !important; 
//   border-radius: 0.8rem;
// }
// .mat-row:hover {
//   background-color: #f8f8f8;
// }

// mat-row, mat-header-row, mat-footer-row {
//   border-bottom-width: 0 !important;
// }

// .box-default {
//   border-radius: 0.8rem;
// }
// .mat-elevation-z8 {
//   border-radius: 0.8rem;
// }
// .item-card .card__image {
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-left-radius: 0.8rem;
//   border-top-right-radius: 0.8rem;
// }
// .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
//   line-height: 28px;
// }
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .with-maxwidth .col {
    flex: 0 0 32% !important;
    max-width: 100%;
  }

  .truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1180px) {
  .truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

.img-svg {
  height: 20px;
  margin-right: 3px;
  margin-top: 2px;
  width: 20px;
  align-self: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-svg-refresh {
  height: 20px;
  width: 24px;
  align-self: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-Security {
  background-image: url("../../assets/images/icons/security_black.svg");
}

.img-Configurations {
  background-image: url("../../assets/images/icons/settings.svg");
}

.svg-style .mat-button-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: baseline !important;
}


.img-description {
  background-image: url("../../assets/images/icons/description.svg");
}

.img-lock {
  background-image: url("../../assets/images/icons/lock.svg");
}

.img-lock-open {
  background-image: url("../../assets/images/icons/lock_open.svg");
}

.img-play-black {
  background-image: url("../../assets/images/icons/play_circle_black.svg");
}

.img-play-white {
  background-image: url("../../assets/images/icons/play_circle_outline.svg");
}

.img-refresh {
  background-image: url("../../assets/images/icons/refresh.svg");
}

.img-close {
  background-image: url("../../assets/images/icons/close.svg");
}