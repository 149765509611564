/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:focus {
  outline: none !important;
}

button[mat-fab], button[mat-mini-fab], button[mat-raised-button],
a[mat-fab],
a[mat-mini-fab],
a[mat-raised-button] {
  text-decoration: none;
}
button[mat-raised-button].mat-warn, button[mat-fab].mat-warn, button[mat-mini-fab].mat-warn, button[mat-raised-button].mat-accent, button[mat-fab].mat-accent, button[mat-mini-fab].mat-accent,
a[mat-raised-button].mat-warn,
a[mat-fab].mat-warn,
a[mat-mini-fab].mat-warn,
a[mat-raised-button].mat-accent,
a[mat-fab].mat-accent,
a[mat-mini-fab].mat-accent {
  color: #fff;
}
button[mat-fab], button[mat-mini-fab],
a[mat-fab],
a[mat-mini-fab] {
  color: #fff;
}
button.btn-lg,
a.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
button.btn-sm,
a.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.mat-menu-content a[mat-menu-item] {
  text-decoration: none;
}
.mat-menu-content a[mat-menu-item]:hover, .mat-menu-content a[mat-menu-item]:focus {
  color: #6b778c;
}

[mat-tab-nav-bar], .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

[mdInput] {
  width: 100%;
}

mat-input-container.mat-icon-left {
  position: relative;
  padding-left: 36px;
}
mat-input-container.mat-icon-left .mat-input-wrapper {
  position: relative;
}
mat-input-container.mat-icon-left .mat-input-infix > mat-icon {
  position: absolute;
  top: 0;
  left: -32px;
  right: auto;
}

.mat-input-container .mat-icon.material-icons {
  font-size: 24px;
}

.mat-radio-group-spacing mat-radio-button {
  margin-right: 20px;
}
.mat-radio-group-spacing mat-radio-button:last-child {
  margin-right: 0;
}

mat-input-container:not(.ng-pristine).ng-invalid .mat-input-underline {
  border-color: #EF5350;
}
mat-input-container:not(.ng-pristine).ng-invalid .mat-input-underline .mat-input-ripple {
  background-color: #EF5350;
}
mat-input-container:not(.ng-pristine).ng-valid .mat-input-underline {
  border-color: #66BB6A;
}
mat-input-container:not(.ng-pristine).ng-valid .mat-input-underline .mat-input-ripple {
  background-color: #66BB6A;
}

.app-sidebar [mat-button], .app-sidebar [mat-raised-button], .app-sidebar [mat-fab], .app-sidebar [mat-icon-button], .app-sidebar [mat-mini-fab] {
  font-weight: normal;
  min-width: inherit;
}

.quickview-inner .mat-list-item .mat-list-item-content {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.quickview-inner .mat-list-item .mat-list-item-content > p {
  flex: 1 1 auto;
  margin: 0;
}
.quickview-inner .mat-list-item .mat-list-item-content .mat-secondary {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0;
  justify-content: flex-end;
}

.app-header .mat-button {
  min-width: 60px;
}

body .cdk-overlay-container {
  z-index: 1001;
}

.page-with-tabs > .page-title {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 30px 15px 30px 39px;
}
.page-with-tabs > .page-title h2 {
  margin: 0;
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #2196F3;
}
.page-with-tabs > mat-tab-group > mat-tab-header {
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.typo-styles dt {
  display: block;
  float: left;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px;
}

.typo-styles dd {
  display: block;
  margin-left: 80px;
  margin-bottom: 20px;
}

.typo-styles .typo-styles__demo {
  margin-bottom: 8px;
}

.page-icons .card .fa,
.page-icons .card .material-icons,
.page-icons .card .wi {
  color: rgba(0, 0, 0, 0.5);
}
.page-icons .card .fa,
.page-icons .card .wi {
  font-size: 20px;
  margin: 5px;
}

.theme-gray .page-icons .card .fa,
.theme-gray .page-icons .card .material-icons,
.theme-gray .page-icons .card .wi,
.theme-dark .page-icons .card .fa,
.theme-dark .page-icons .card .material-icons,
.theme-dark .page-icons .card .wi {
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.7;
}

.page-grids .grid-structure .row {
  margin-top: 0.8rem;
}
.page-grids .grid-structure .row .widget-container {
  margin-top: 5px;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px 15px 12px;
  font-size: 0.875rem;
  min-height: 0;
  border-radius: 0.2rem;
}

.color-palette {
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 60px;
}
.color-palette ul {
  margin: 0;
  padding: 0;
}
.color-palette .dark {
  color: rgba(0, 0, 0, 0.87);
}
.color-palette .color-group {
  padding-bottom: 40px;
}
.color-palette .color-group:first-child,
.color-palette .color-group:nth-of-type(3n+1),
.color-palette .color-group:last-child:first-child,
.color-palette .color-group:last-child:nth-of-type(3n+1) {
  clear: left;
  margin-left: 0;
}
.color-palette .color-group li.divide,
.color-palette .color-group:last-child li.divide {
  border-top: 4px solid #fafafa;
}
.color-palette .color-group li.color,
.color-palette .color-group:last-child li.color {
  padding: 15px;
}
.color-palette .color-group li,
.color-palette .color-group:last-child li {
  list-style-type: none;
}
.color-palette .color-group li.main-color,
.color-palette .color-group:last-child li.main-color {
  border-bottom: 4px solid #fafafa;
}
.color-palette .color-group li.main-color .name,
.color-palette .color-group:last-child li.main-color .name {
  display: block;
  margin-bottom: 60px;
}
.color-palette .color-group li.color .hex,
.color-palette .color-group:last-child li.color .hex {
  float: right;
  text-transform: uppercase;
}

.page-auth {
  background-color: #e9ecef;
  min-height: 100%;
  background-size: cover;
  padding: 0 10px;
}
.page-auth .card {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 15px;
}
.page-auth .logo {
  margin-bottom: 1.3em;
}
.page-auth .logo.text-center {
  padding: 0;
}
.page-auth .logo a {
  font-weight: normal;
  text-decoration: none;
}
.page-auth .main-body {
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  padding: 50px 0 20px;
}
@media (min-width: 768px) {
  .page-auth .main-body {
    padding-top: 70px;
  }
}
.page-auth .additional-info {
  color: #868e96;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 10px 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.875rem;
}
.page-auth .additional-info a {
  color: #636c72;
}
.page-auth .additional-info .divider-h {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 15px;
}
.page-auth.page-confirm-email .logo {
  font-size: 24px;
  margin-bottom: 0.8em;
}
.page-auth .confirm-mail-icon {
  text-align: center;
}
.page-auth .confirm-mail-icon .material-icons {
  color: #636c72;
  font-size: 100px;
}

.page-err {
  background-color: #343a40;
  height: 100%;
  position: relative;
}
.page-err .err-container {
  background-color: #343a40;
  padding: 45px 10px 0;
}
@media (min-width: 768px) {
  .page-err .err-container {
    padding: 100px 0 0;
  }
}
.page-err .err {
  color: #fafafa;
}
.page-err .err h1 {
  margin-bottom: 35px;
  color: #fafafa;
  color: rgba(255, 255, 255, 0.8);
  font-size: 150px;
  font-weight: 300;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .page-err .err h1 {
    font-size: 180px;
  }
}
.page-err .err h2 {
  color: #fafafa;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-weight: 300;
  font-size: 28px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .page-err .err h2 {
    font-size: 36px;
  }
}
.page-err .err-body {
  padding: 20px 10px;
}
.page-err .btn-goback {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.8);
}
.page-err .btn-goback:hover, .page-err .btn-goback:focus, .page-err .btn-goback:active, .page-err .btn-goback.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.open .page-err .btn-goback.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.page-err .footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.page-lock {
  height: 100%;
  background-size: cover;
}
.page-lock .lock-centered {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -65px;
}
@media screen and (min-width: 768px) {
  .page-lock .lock-centered {
    margin-top: -75px;
  }
}
.page-lock .lock-container {
  position: relative;
  max-width: 420px;
  margin: 0 auto;
}
.page-lock .lock-box {
  position: absolute;
  left: 0;
  right: 0;
}
.page-lock .lock-box .lock-user {
  background: #fff;
  width: 50%;
  float: left;
  height: 50px;
  line-height: 50px;
  margin-top: 50px;
  padding: 0 20px;
  border-left-radius: 0.2rem 0 0 0.2rem;
  color: #868e96;
}
.page-lock .lock-box .lock-img img {
  position: absolute;
  border-radius: 50%;
  left: 40%;
  width: 80px;
  height: 80px;
  border: 6px solid #fff;
  background: #fff;
}
@media screen and (min-width: 768px) {
  .page-lock .lock-box .lock-img img {
    left: 33%;
    width: 150px;
    height: 150px;
    border: 10px solid #fff;
  }
}
.page-lock .lock-box .lock-pwd {
  background: #fff;
  width: 50%;
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0 0 0 50px;
  margin-top: 50px;
  border-right-radius: 0 0.2rem 0.2rem 0;
  color: #2196F3;
}
@media screen and (min-width: 768px) {
  .page-lock .lock-box .lock-pwd {
    padding: 0 0 0 80px;
  }
}
.page-lock .lock-box .lock-pwd input {
  width: 80%;
  height: 50px;
  color: #495057;
  border: 0;
}
.page-lock .lock-box .lock-pwd .btn-submit {
  position: absolute;
  top: 50%;
  right: 20px;
  color: #6b778c;
}
.page-lock .lock-box .lock-pwd .btn-submit .material-icons {
  line-height: 50px;
  height: 50px;
}

.page-profile .profile-header {
  position: relative;
  margin: 0 0 15px;
  padding: 50px 30px 90px;
  background-size: cover;
}
.page-profile .profile-img {
  display: inline-block;
  margin-right: 20px;
}
.page-profile .profile-img img {
  max-width: 120px;
  height: auto;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(0, 0, 0, 0.2);
}
.page-profile .profile-social {
  display: inline-block;
}
.page-profile .profile-social > a {
  margin-right: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.page-profile .profile-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  padding: 10px 30px;
  color: #fafafa;
}
.page-profile .profile-info ul {
  margin: 0;
}
.page-profile img.media-object {
  border-radius: 0.2rem;
}

.page-invoice {
  color: #6b778c;
}
.page-invoice .invoice-wrapper {
  padding: 0 0 30px;
  background-color: #fff;
}

.invoice-inner {
  padding: 15px 15px 30px;
  background-color: #fff;
}
.invoice-inner .invoice-sum li {
  margin-bottom: 5px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
}
.invoice-inner .table.table-bordered {
  border: 0;
}
.invoice-inner .table .bg-color-dark > th {
  border: 0;
}

.page-dashboard h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 30px;
}
.page-dashboard .box {
  position: relative;
  border-radius: 0.2rem;
}
.page-dashboard .box .box-top,
.page-dashboard .box .box-bottom {
  height: 85px;
  padding: 18px 15px;
  font-size: 35px;
  line-height: 1.5;
  text-align: center;
  font-weight: 300;
}
.page-dashboard .box .box-top .material-icons,
.page-dashboard .box .box-bottom .material-icons {
  font-size: 40px;
}
.page-dashboard .box .box-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.page-dashboard .box .box-info {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -12px;
  text-align: center;
}
.page-dashboard .box .box-info span {
  height: 24px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  line-height: 14px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 11px;
  color: #868e96;
  border-radius: 1em;
}

.theme-gray .page-dashboard .box .box-info span {
  background-color: #444;
}

.theme-dark .page-dashboard .box .box-info span {
  background-color: #3a4047;
}

.vprogressbar-container {
  height: 250px;
  margin-top: 25px;
  border-bottom: rgba(221, 221, 221, 0.3) 2px solid;
  position: relative;
}
.vprogressbar-container.brand-info {
  border-color: #00BCD4;
}
.vprogressbar-container.brand-success {
  border-color: #66BB6A;
}
.vprogressbar-container .vprogressbar {
  padding-left: 10px;
}
.vprogressbar-container .vprogressbar li {
  position: relative;
  height: 248px;
  width: 35px;
  background: rgba(221, 221, 221, 0.3);
  margin-right: 18px;
  float: left;
  list-style: none;
}
.vprogressbar-container .vprogressbar-percent {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.vprogressbar-container .vprogressbar-legend {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-left: 0;
  padding: 5px 10px;
  text-align: left;
  border-radius: 0.2rem;
  background: rgba(255, 255, 255, 0.15);
}
.vprogressbar-container .vprogressbar-legend li {
  display: block;
  font-size: 11px;
  margin-bottom: 5px;
  list-style: none;
}
.vprogressbar-container .vprogressbar-legend .vpointer {
  height: 10px;
  width: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.vprogressbar-container .vprogressbar-info {
  color: #ccc;
}

.theme-gray .box-info .box-icon .material-icons,
.theme-dark .box-info .box-icon .material-icons {
  color: rgba(255, 255, 255, 0.54);
}
.theme-gray .box-info .box-num,
.theme-dark .box-info .box-num {
  color: rgba(255, 255, 255, 0.54);
}

.page-maintenance .top-header {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 32px;
  line-height: 1;
}
.page-maintenance .top-header a.logo {
  text-decoration: none;
  color: #6b778c;
}
.page-maintenance .content {
  max-width: 1140px;
  margin: 50px auto 0;
}
.page-maintenance .main-content {
  margin-bottom: 80px;
}
.page-maintenance .main-content h1 {
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 15px;
}
.page-maintenance .main-content p {
  font-size: 22px;
}

.theme-dark .page-maintenance .top-header a.logo,
.theme-gray .page-maintenance .top-header a.logo {
  color: #fff;
}

.page-about .hero.hero-bg-img {
  background-size: cover;
  padding: 0;
}
.page-about .hero .hero-inner {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 90px 0;
}
.page-about .hero .hero-title,
.page-about .hero .hero-tagline {
  color: #fff;
}
.page-about .stat-container {
  margin-bottom: 30px;
}
.page-about .stat-container .stat-item {
  margin-bottom: 20px;
  border-bottom: 2px solid #f5f5f5;
}
.page-about .stat-container .stat-num {
  display: block;
  color: #2196F3;
  font-size: 72px;
  font-weight: 300;
  line-height: 66px;
}
.page-about .stat-container .stat-desc {
  display: inline-block;
  margin-bottom: -2px;
  padding-bottom: 20px;
  border-bottom: 2px solid #2196F3;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}
.page-about .article:nth-of-type(1) {
  padding-top: 75px;
}
.page-about .space-bar {
  padding: 3px;
  border-radius: 0.2rem;
  margin-right: 5px;
}

.page-terms h4 {
  margin-top: 2em;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.blog-item {
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  margin-top: 70px;
  padding: 70px 0 10px;
}
.blog-item:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}
.blog-item h2 {
  font-size: 35px;
  line-height: 1;
}
.blog-item h2 a {
  color: #6b778c;
  font-weight: 300;
  text-decoration: none;
}
.blog-item .blog-info {
  margin: 10px 0;
}
.blog-item .blog-info > span {
  margin-right: 8px;
}
.blog-item .blog-info .avatar {
  width: 30px;
  height: auto;
  border-radius: 50%;
}
.blog-item .blog-info .date {
  opacity: 0.7;
}
.blog-item .blog-info .category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 0.2rem;
  background-color: rgba(0, 0, 0, 0.08);
}
.blog-item .blog-info .category a {
  color: #6b778c;
  text-decoration: none;
  opacity: 0.7;
}
.blog-item .desc {
  font-size: 16px;
  opacity: 0.7;
}

.theme-gray .blog-item h2 a,
.theme-dark .blog-item h2 a {
  color: rgba(255, 255, 255, 0.7);
}
.theme-gray .blog-item .blog-info .category a,
.theme-dark .blog-item .blog-info .category a {
  color: rgba(255, 255, 255, 0.7);
}

.spinner-center {
  margin: 0 auto;
}

.spinner-center {
  margin: 0 auto;
}